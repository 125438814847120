import React, { useEffect, useState } from "react";
import { wcGet } from "../services/WCService";
import { LeftCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Table } from 'reactstrap'
import { Spin, Button, Modal, Form, Checkbox, InputNumber, Alert, DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const refundable_status = ['processing', 'on-hold', 'confirmed', 'cancelled', 'completed']

const ManageOrders = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentOrder, setCurrentOrder] = useState(null)
  const [open, setOpen] = useState(false)
  const [fullAmount, setFullAmount] = useState(false)
  const [fullRefund, setFullRefund] = useState(false)
  const [showFullAmount, setShowFullAmount] = useState(true)
  const [maxRefundVal, setMaxRefundVal] = useState(10000)

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    if (authUser) {
      getAllOrders(authUser.website_url, currentPage);
    }
  }, [currentPage]);

  const getAllOrders = (apiUrl, currentPage) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true);
    wcGet(
      `${apiUrl}/wp-json/wc/v3/orders?page=${currentPage}&per_page=10&&consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`)
      .then((res) => {
        setLoading(false);
        setOrders(res.data)
        setTotalPages(Number(res.headers["x-wp-totalpages"]));
      }).catch(error => {
        console.log(error)
      });
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  }

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  }

  const goHome = () => {
    history.push("/home");
  };

  const refundOrder = (item) => {
    setCurrentOrder(item)
    let total_refund = 0
    if (item.refunds.length > 0) {
      item.refunds.map((item) => {
        total_refund = total_refund + Math.abs(Number(item?.total))
      })
      if (total_refund.toFixed(2) === item.total) {
        setFullRefund(true)
      } else {
        const maxVal = Number(item.total) - Number(total_refund.toFixed(2))
        setMaxRefundVal(maxVal.toFixed(2))

        if(maxVal === Number(item.total)) {
          setShowFullAmount(true)
        } else {
          setShowFullAmount(false)
        }
        setOpen(true)
      }
    } else {
      setOpen(true)
    }

  }

  const changeFullAmount = (e) => {
    setFullAmount(e.target.checked)
  }


  const onFinish = (values) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    if (fullAmount) {
      if (refundable_status.includes(currentOrder.status)) {
        setLoading(true);
        const refundData = {
          amount:  currentOrder.total.toString()
        };
        axios.post(
          `${authUser.website_url}/wp-json/wc/v3/orders/${currentOrder.id}/refunds?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`, refundData)
          .then((res) => {
            setLoading(false);
            setOpen(false)
            setFullAmount(false)
            if(res.status === 201) {
              const refund_data =  {id: res.data.id, reason: '', total: `-${res.data.amount}`}
              setOrders(prevState =>
                prevState.map(obj =>
                  obj.id === currentOrder.id ? { ...obj, refunds: [...obj.refunds, refund_data], status: 'refunded' } : obj
                )
              );
            }
          }).catch(error => {
            console.log(error)
          });
      } else {
        alert('Can not refund this order!')
      }
    } else {
      if (refundable_status.includes(currentOrder.status)) {
        setLoading(true);
        const refundData = {
          amount: values.refund_amount.toString()
        };
        axios.post(
          `${authUser.website_url}/wp-json/wc/v3/orders/${currentOrder.id}/refunds?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`, refundData)
          .then((res) => {
            setLoading(false);
            setOpen(false)
            setFullAmount(false)
            if(res.status === 201) {
              const refund_data =  {id: res.data.id, reason: '', total: `-${res.data.amount}`}
              //calculate the refunded values+
              let original_refund_amount = 0
              let total_refunded_amount = 0
              if(currentOrder.refunds.length > 0) {
                currentOrder.refunds.map((item) => {
                  original_refund_amount = original_refund_amount + Math.abs(Number(item?.total))
                })
              }
              total_refunded_amount = original_refund_amount + Math.abs(Number(res.data.amount))

              if(total_refunded_amount.toFixed(2) === currentOrder.total) {
                setOrders(prevState =>
                  prevState.map(obj =>
                    obj.id === currentOrder.id ? { ...obj, refunds: [...obj.refunds, refund_data], status: 'refunded' } : obj
                  )
                );
              } else {
                setOrders(prevState =>
                  prevState.map(obj =>
                    obj.id === currentOrder.id ? { ...obj, refunds: [...obj.refunds, refund_data] } : obj
                  )
                );
              }
            }
          }).catch(error => {
            console.log(error)
          });
      } else {
        alert('Can not refund this order!')
      }
    }

  };

  const onCloseRefund = () => {
    setFullRefund(false)
  }

  const filderOrderByDate = (date, dateString) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    if(dateString[0] === '' && dateString[1] === '') {
      getAllOrders(authUser.website_url, currentPage);
    } else {
      const filterData = {
        after: dateString[0] + 'T00:00:00Z',
        before: dateString[1] + 'T23:59:59Z'
      }
      setLoading(true);
      axios.get(
        `${authUser.website_url}/wp-json/wc/v3/orders?after=${filterData.after}&before=${filterData.before}&consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`, filterData)
        .then((res) => {
          setLoading(false);
          setOrders(res.data)
          setTotalPages(Number(res.headers["x-wp-totalpages"]));
        }).catch(error => {
          console.log(error)
        });
    }
   
  }

  return (
    <>
      <div className="container">
        <div className="product-header mt-4 ml-2">
          <LeftCircleOutlined style={{ fontSize: "150%" }} onClick={goHome} />{" "}
          Back
        </div>

        {fullRefund && (
          <div className="full-refund-alert" style={{ marginTop: 30 }}>
            <Alert
              message="Fully Refunded"
              description="Already Refunded."
              type="error"
              closable
              onClose={onCloseRefund}
            />
          </div>

        )}

        <div className="coupon-title" style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>
          Order Management
        </div>

        <div className="search-order d-flex " style={{ marginBottom: 20, alignItems: 'end', float: 'right' }}>
          <RangePicker onChange={filderOrderByDate} />
        </div>

        {loading && <Spin />}
        <div className="coupon-table" style={{ marginTop: 10 }}>
          <Table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer</th>
                <th>Total</th>
                <th>Payment Method</th>
                <th>Order Date</th>
                <th>Order Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((item, index) => {
                if (item.refunds.lenth === 0) {
                  return (
                    <tr key={index}>
                      <td>{item?.id}</td>
                      <td>{item?.billing?.first_name + ' ' + item?.billing.last_name}</td>
                      <td>{item?.total}</td>
                      <td>
                        {item?.payment_method === 'cod' ? 'Cash' : item?.payment_method === 'stripe' ? 'Card' : ''}
                      </td>
                      <td>{item?.date_created?.split('T')[0]}</td>
                      <td>{item?.status}</td>
                      <td>{item?.payment_method === 'stripe' && <Button type="primary" onClick={() => refundOrder(item)}> Refund </Button>}</td>
                    </tr>
                  )
                } else {
                  return (
                    <tr key={'adsfa' + index}>
                      <td>{item?.id}</td>
                      <td>{item?.billing?.first_name + ' ' + item?.billing.last_name}</td>
                      <td>
                        <div>{item?.total}</div>
                        {item.refunds.map((refundItem, i) => {
                          return (
                            <div>{refundItem?.total + '(refunded)'}</div>
                          )
                        })}
                      </td>
                      {item?.payment_method === 'cod' ? 'Cash' : item?.payment_method === 'stripe' ? 'Card' : ''}
                      <td>{item?.date_created?.split('T')[0]}</td>
                      <td>{item?.status}</td>
                      <td>{item?.payment_method === 'stripe' && <Button type="primary" onClick={() => refundOrder(item)}> Refund </Button>}</td>
                    </tr>

                  )
                }
              })}
            </tbody>
          </Table>
          <div style={{ paddingBottom: 50 }}>
            <div className="d-flex justify-content-end" >
              <button onClick={handlePrevPage} disabled={currentPage === 1} style={{ marginRight: 10 }}>Previous Page</button>
              <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next Page</button>
            </div>
          </div>

        </div>

        <Modal title="Refund"
          open={open}
          onCancel={() => setOpen(false)}
          footer={[
            <Button key="cancel" onClick={() => setOpen(false)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" htmlType="submit" form="my-form">
              Submit
            </Button>,
          ]}>
          <Form
            id="my-form"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            {loading && <Spin />}

            {showFullAmount && (
              <Form.Item
                name="full_amount"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Checkbox checked={fullAmount} onChange={changeFullAmount}>Full Amount</Checkbox>
              </Form.Item>
            )}


            {!fullAmount && (
              <Form.Item
                label="Refund Amount"
                name="refund_amount"
                rules={[
                  {
                    required: true,
                    message: 'Please input refund amount!',
                  },
                ]}
              >
                <InputNumber min={0} max={maxRefundVal} />
              </Form.Item>
            )}

          </Form>
        </Modal>
      </div>
    </>
  );
};

export default ManageOrders;
