import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import React, { Suspense } from "react";
import { authRoutes, adminRoutes, userRoutes } from "./routes/allRoutes";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import Adminmiddleware from "./routes/middleware/Adminmiddleware";
import Usermiddleware from "./routes/middleware/Usermiddleware";

const App = () => {
  return (
    <div className="">
      <Suspense fallback={<div className="loading" />}>
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                isAdminProtected={false}
                exact
              />
            ))}

            {adminRoutes.map((route, idx) => (
              <Adminmiddleware
                path={route.path}
                component={route.component}
                key={idx}
                exact
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Usermiddleware
                path={route.path}
                component={route.component}
                key={idx}
                exact
              />
            ))}
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
