export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_ADMIN = "LOGIN_ADMIN"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"

export const LOGOUT_ADMIN = "LOGOUT_ADMIN"


export const API_ERROR = "LOGIN_API_ERROR"
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO'

export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS"

