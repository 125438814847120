import {GET_ALL_USERS, GET_ALL_USERS_SUCCESS, DELETE_USER_BYID, DELETE_USER_BYID_SUCCESS, CREATE_USER, CREATE_USER_SUCCESS, SET_USER, EDIT_USER, EDIT_USER_SUCCESS} from "./actionTypes"

export const getAllUsers = () => {
  return {
    type: GET_ALL_USERS,
  }
}

export const getAllUserSuccess = (users) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: users,
  }
}

export const deleteUser = (userId) => {
  return {
    type: DELETE_USER_BYID,
    payload: {userId}
  }
}

export const deleteUserSuccess = (userId) => {
  return {
    type: DELETE_USER_BYID_SUCCESS,
    payload: userId,
  }
}

export const createUser = (userData) => {
  return {
    type: CREATE_USER,
    payload: userData
  }
}

export const createUserSuccess = (userData) => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: userData,
  }
}

export const setUserData = (userData) => {
  return {
    type: SET_USER,
    payload: userData
  }
}


export const editWebsite = (id, userData) => {
  return {
    type: EDIT_USER,
    payload: {id, userData}
  }
}

export const editUserSuccess = (userData) => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: userData,
  }
}













