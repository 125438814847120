import React from "react";
import { Route, Redirect } from "react-router-dom";

const Usermiddleware = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const user = localStorage.getItem("auth_user");
      if (!user) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      return <Component {...props} />;
    }}
  />
);
export default Usermiddleware;
