import React, { useEffect, useState } from "react";
import { Button, Image, Spinner, Alert } from "react-bootstrap";
import { wcPost, wcGet } from "../services/WCService";
import logo from "../assets/images/logo.png";
import {
  GetIpAddress,
  getDeviceDetail,
  sendEmail,
} from "../services/Utils";
import Schedule from "../components/Schedule";
import { useHistory } from "react-router-dom";
import { PoweroffOutlined } from "@ant-design/icons";

const Home = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [result_message, SetResultMessage] = useState("");
  const [variant, SetVariant] = useState("");
  const [restaurantName, SetResturantName] = useState("");
  const [ipAddress, SetIpAddress] = useState("0.0.0.0");
  const [device_info, SetDeviceInfo] = useState("");
  const [apiUrl, SetApiUrl] = useState("");
  const [origin, SetOrigin] = useState("");
  const [deliveryTimes, SetDeliveryTimes] = useState(null);
  const [pickupTimes, SetPickupTimes] = useState(null);

  useEffect(() => {
    GetIpAddress().then((res) => {
      SetIpAddress(res.data.ipAddress);
    });
    getDeviceDetail().then((res) => {
      SetDeviceInfo(res.deviceType + " " + res.browserName);
    });
  }, []);

  useEffect(() => {
      const authUser =  JSON.parse(localStorage.getItem('auth_user'));
      if(authUser) {
        const restaurant_name = authUser.website_url.replace(`https://`, '')
        SetResturantName(restaurant_name);
        SetApiUrl(authUser.website_url);
        SetOrigin(authUser.website_url);
        getRestaurantStatus(authUser.website_url);
       
      }
  }, []);

  const getTimes = () => {
    const authUser =  JSON.parse(localStorage.getItem('auth_user'));
    getRestaurantStatus(authUser.website_url);
  }

  const getRestaurantStatus = (apiUrl) => {
    setLoading(true);
    wcGet(`${apiUrl}/wp-json/xc/v1/restaurant-status`).then((res) => {
      setLoading(false);
      SetDeliveryTimes(res.data.times);
      SetPickupTimes(res.data.times4pickup);
    });
  };

  const openShop = () => {
    let queryParams = "shop_open";
    setLoading(true);
    wcPost(`${apiUrl}/wp-json/xc/v1/restaurant-open-close`, queryParams)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data.success) {
            SetVariant("success");
            SetResultMessage("Restaurant Opened successfully !");
            const emailData = {
              ApiUrl: origin,
              subject: "Store Open Request",
              restaurant_name: restaurantName,
              status: "open",
              date: new Date().toLocaleString(),
              device_info: device_info,
              ipAddress: ipAddress,
            };
            sendEmail(emailData);
          } else {
            SetVariant("danger");
            SetResultMessage("Restaurant was already opened !");
          }
        } else {
          alert("error");
        }
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  };

  const closeShop = () => {
    let queryParams = "shop_close";
    setLoading(true);
    wcPost(`${apiUrl}/wp-json/xc/v1/restaurant-open-close`, queryParams)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data.success) {
            SetVariant("success");
            SetResultMessage("Restaurant Closed successfully !");
            const emailData = {
              ApiUrl: origin,
              subject: "Store Closed Request",
              restaurant_name: restaurantName,
              status: "closed",
              date: new Date().toLocaleString(),
              device_info: device_info,
              ipAddress: ipAddress,
            };
            sendEmail(emailData);
          } else {
            SetVariant("danger");
            SetResultMessage("Restaurant was already closed !");
          }
        } else {
          alert("error");
        }
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  };

  const goToManageProduct = () => {
    history.push(`/manage-product`);
  };

  const goToCoupons = () => {
    history.push(`/coupons`);
  };

  const goHome = () => {
    history.push("/");
  };

  const logOut = async () => {
    localStorage.removeItem("auth_user");
    localStorage.removeItem("access_token");
    history.push("/login");
  };

  const getEmailDataOpenHour = () => {
    const mailData = {
      ApiUrl: origin,
      subject: "Store Hours Request",
      restaurant_name: restaurantName,
      date: new Date().toLocaleString(),
      device_info: device_info,
      ipAddress: ipAddress
    }
    return mailData
  }

  const goToOrders = () => {
    history.push(`/orders`);
  }

  return (
    <>
      <div className="container">
        <div
          className="justify-content-center items-center mt-4"
          style={{ display: "grid" }}
        >
          <div style={{ textAlign: "center" }}>
            <Image
              src={logo}
              rounded
              style={{ margin: "0 auto", width: 200 }}
              onClick={goHome}
            />
            <PoweroffOutlined
              style={{ fontSize: 30, color: "red" }}
              onClick={logOut}
            />
          </div>

          <Alert variant={variant}>{result_message}</Alert>
          <div>
            <div className="" style={{ textAlign: "center" }}>
              <Button className="mr-2" variant="success" onClick={openShop}>
                Open Shop
              </Button>
              <Button variant="danger" onClick={closeShop}>
                Close Shop
              </Button>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="" style={{ textAlign: "center", margin: 5 }}>
              <Button
                className="mt-4"
                variant="success"
                onClick={goToManageProduct}
              >
                Manage Product
              </Button>
            </div>
            <div className="" style={{ textAlign: "center", margin: 5 }}>
              <Button
                className="mt-4"
                variant="success"
                onClick={goToCoupons}
              >
                Coupons
              </Button>
            </div>
            <div className="" style={{ textAlign: "center", margin: 5 }}>
              <Button
                className="mt-4"
                variant="success"
                onClick={goToOrders}
              >
                Orders
              </Button>
            </div>
          </div>

          <div className="mt-4 d-flex justify-content-center">
            {loading ? <Spinner animation="border" variant="success" /> : ""}
          </div>
        </div>

        <div className="opening-hours">
          <h5>Opening Hours:</h5>

          {deliveryTimes && pickupTimes && (
            <div>
              <Schedule
                title="Monday"
                deliveryTimes={deliveryTimes[0]}
                pickupTimes={pickupTimes[0]}
                weekPickup = {pickupTimes}
                weekDelivery = {deliveryTimes}
                apiUrl = {apiUrl}
                getTimes = {getTimes}
                getEmailDataOpenHour = {getEmailDataOpenHour}
              />

              <Schedule
                title="Tuesday"
                deliveryTimes={deliveryTimes[1]}
                pickupTimes={pickupTimes[1]}
                weekPickup = {pickupTimes}
                weekDelivery = {deliveryTimes}
                apiUrl = {apiUrl}
                getTimes = {getTimes}
                getEmailDataOpenHour = {getEmailDataOpenHour}


              />

              <Schedule
                title="Wednesday"
                deliveryTimes={deliveryTimes[2]}
                pickupTimes={pickupTimes[2]}
                weekPickup = {pickupTimes}
                weekDelivery = {deliveryTimes}
                apiUrl = {apiUrl}
                getTimes = {getTimes}
                getEmailDataOpenHour = {getEmailDataOpenHour}


              />

              <Schedule
                title="Thursday"
                deliveryTimes={deliveryTimes[3]}
                pickupTimes={pickupTimes[3]}
                weekPickup = {pickupTimes}
                weekDelivery = {deliveryTimes}
                apiUrl = {apiUrl}
                getTimes = {getTimes}
                getEmailDataOpenHour = {getEmailDataOpenHour}


              />

              <Schedule
                title="Friday"
                deliveryTimes={deliveryTimes[4]}
                pickupTimes={pickupTimes[4]}
                weekPickup = {pickupTimes}
                weekDelivery = {deliveryTimes}
                apiUrl = {apiUrl}
                getTimes = {getTimes}
                getEmailDataOpenHour = {getEmailDataOpenHour}


              />

              <Schedule
                title="Saturday"
                deliveryTimes={deliveryTimes[5]}
                pickupTimes={pickupTimes[5]}
                weekPickup = {pickupTimes}
                weekDelivery = {deliveryTimes}
                apiUrl = {apiUrl}
                getTimes = {getTimes}
                getEmailDataOpenHour = {getEmailDataOpenHour}


              />

              <Schedule
                title="Sunday"
                deliveryTimes={deliveryTimes[6]}
                pickupTimes={pickupTimes[6]}
                weekPickup = {pickupTimes}
                weekDelivery = {deliveryTimes}
                apiUrl = {apiUrl}
                getTimes = {getTimes}
                getEmailDataOpenHour = {getEmailDataOpenHour}


              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
