import { call, put, takeEvery } from "redux-saga/effects"
import { LOGIN_USER, LOGIN_ADMIN, LOGOUT_USER, LOGOUT_ADMIN } from "./actionTypes"
import { apiError } from "./actions"
import { axiosApi } from '../../../helpers/api_helper'
import {adminLogin, loginUserAsyc} from '../../../helpers/backend_helper'

function* loginUser({ payload: { user, history } }) {
  try {
    console.log('user =>', user)
    const response = yield call(loginUserAsyc, {
      email: user.email,
      password: user.password,
    })

    if(response.data.success) {
      axiosApi.defaults.headers.common["Authorization"] = "Bearer " + response.data.token
      localStorage.setItem("access_token", JSON.stringify(response.data.token))
      history.push('/home')
    } else {
      console.log('login error')
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* loginAdminUser({ payload: { adminInfo, history } }) {
  try {
    const response = yield call(adminLogin, {
      email: adminInfo.email,
      password: adminInfo.password,
    })
    if(response.data.success) {
      axiosApi.defaults.headers.common["Authorization"] = "Bearer " + response.data.token
      localStorage.setItem("access_token", JSON.stringify(response.data.token))
      history.push('/admin/users')
    } else {
      console.log('response =>', response)
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("access_token")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutAdmin({payload: {history}}) {
  try {
    localStorage.removeItem("access_token")
    localStorage.removeItem("auth_admin")
    history.push("/admin/login")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGIN_ADMIN, loginAdminUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(LOGOUT_ADMIN, logoutAdmin)

}

export default authSaga
