import { all, fork } from "redux-saga/effects"
import AuthSaga from "./auth/login/saga"
import userSaga from './user/saga'

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(userSaga),
  ])
}
