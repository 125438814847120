import axios from 'axios';

export const wcPost = async (apiUrl, queryParams = '') => {
  return await axios.post(`${apiUrl}?action=${queryParams}`)
}

export const wcUpdateHours = async (apiUrl, values) => {
  return await axios.post(`${apiUrl}`, values)
}

export const wcGet = async (apiUrl) => {
  return await axios.get(`${apiUrl}`)
}

export const wcPut = async (apiUrl, values) => {
  return await axios.put(`${apiUrl}`, values)
}

