import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Button,
  Drawer,
  Popconfirm,
  Checkbox,
  Form,
  Input,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers, deleteUser, createUser, editWebsite } from "../../store/actions";

const UserManager = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [changePass, setChangePass] = useState(false);
  const [currentUser, setCurrentUser] = useState(null)
  const { users } = useSelector((state) => state.user);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Web Site",
      dataIndex: "website_url",
      key: "website_url",
    },
    {
      title: "Custom Key",
      dataIndex: "custom_key",
      key: "custom_key",
    },
    {
      title: "Custom Secret",
      dataIndex: "custom_secret",
      key: "custom_secret",
    },
    {
      title: "Action",
      key: "action",
      render: (_, user) => (
        <Space size="middle">
          <a style={{ color: "green" }} onClick={() => editUser(user)}>
            {" "}
            Edit{" "}
          </a>
          <Popconfirm
            title="Delete the User"
            description="Are you sure to delete this User?"
            onConfirm={() => confirm(user)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link"> Delete </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const showDrawer = () => {
    const initailData = {
      email: "",
      password: "",
      website_url: "",
      custom_key: "",
      custom_secret: "",
    };
    form.setFieldsValue(initailData);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setEditOpen(false);
  };

  const confirm = (userData) => {
    const userId = userData.id;
    dispatch(deleteUser(userId));
  };

  const onFinish = (values) => {
    if (open) {
      dispatch(createUser(values));
      setOpen(false);
    }
    if (editOpen) {
      if (currentUser?.id) {
        console.log('values =>', values)
        dispatch(editWebsite(currentUser.id, values))
        setEditOpen(false);
      } else {
        console.log('user does not exist')
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const cancel = (e) => { };

  const editUser = (userData) => {
    form.setFieldsValue(userData);
    setCurrentUser(userData)
    setEditOpen(true);
  };

  const onChangePassword = (e) => {
    setChangePass(e.target.checked);
  };


  return (
    <div>
      <Button type="primary" onClick={showDrawer}>
        Create the Website
      </Button>
      <Table columns={columns} dataSource={users} />
      <Drawer
        title={open ? "Create Website" : "Edit Website"}
        placement="right"
        onClose={onClose}
        open={open || editOpen}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {open && (
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}

          <Form.Item
            label="Website Url"
            name="website_url"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Custom Key"
            name="custom_key"
            rules={[
              {
                required: true,
                message: "Please input user's custom key!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Custom Secret"
            name="custom_secret"
            rules={[
              {
                required: true,
                message: "Please input user's custom_secret!",
              },
            ]}
          >
            <Input />
          </Form.Item>
         
          {editOpen && (
            <div>
              <Checkbox onChange={onChangePassword}>Change Password</Checkbox>
              {changePass && (
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              )}
            </div>
          )}

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
            style={{ marginTop: 50 }}
          >
            {open && (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
            {editOpen && (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};
export default UserManager;
