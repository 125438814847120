export const ADMIN_LOGIN = '/admin/login'
export const ADMIN_VEIRFY = '/admin/verify-token'
export const GET_ALL_USERS = '/admin/users'
export const DELETE_USER_BYID = '/admin/users'
export const CREATE_USER = '/admin/users'
export const EDIT_USER = '/admin/users'
export const USER_LOGIN = '/login'
export const USER_VEIRFY = '/user/verify-token'

export const SEND_EMAIL = '/admin/sendemail'

