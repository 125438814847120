import React, { useEffect } from "react";
import { apiError, loginAdminUser } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Spin } from 'antd';

const AdminLogin = (props) => {
  const [email, setUseremail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.login);


  const handleValidSubmit = (ev) => {
    ev.preventDefault();
    if (!email || !password) {
      return;
    }
    const loginInfo = {
      email: email,
      password: password
    }
    dispatch(loginAdminUser(loginInfo, props.history));
  };

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container>
          <Row>
            <Col>
              <Card style={{maxWidth: 600, margin: 'auto', marginTop: 300}}>
                <CardBody>
                  <h2>Admin Login</h2>
                  {loading && (
                    <Spin />
                  )}
                  <Form onSubmit={handleValidSubmit}>
                    <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                      <Label for="exampleEmail" className="mr-sm-2">
                        Email
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder=""
                        onChange={(ev) => setUseremail(ev.currentTarget.value)}
                      />
                    </FormGroup>
                    <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                      <Label for="examplePassword" className="mr-sm-2">
                        Password
                      </Label>
                      <Input
                        type="password"
                        name="password"
                        id="examplePassword"
                        placeholder=""
                        onChange={(ev) => setPassword(ev.currentTarget.value)}
                      />
                    </FormGroup>
                    <Button type="submit" color="primary">
                      Login
                    </Button>
                  </Form>
                </CardBody>
              </Card>
             
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminLogin;
