import {
  GET_ALL_USERS_SUCCESS,
  DELETE_USER_BYID_SUCCESS,
  CREATE_USER_SUCCESS,
  SET_USER,
  EDIT_USER_SUCCESS
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  users: [],
  singleUser: null,
  authUser: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    case DELETE_USER_BYID_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case SET_USER:
      return {
        ...state,
        authUser: action.payload,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => user.id === action.payload.id ? (user = action.payload) : user),
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default user;
