import { call, put, takeEvery } from "redux-saga/effects"
import {GET_ALL_USERS, DELETE_USER_BYID, CREATE_USER, EDIT_USER} from "./actionTypes"
import {getAllUsersAsync, deleteUserAsync, creatUserAsync, eidtUserAsync} from '../../helpers/backend_helper'
import {getAllUserSuccess, deleteUserSuccess, createUserSuccess, editUserSuccess} from './actions'
import {notification } from 'antd';

function* getAllUsers() {
  try {
    const response = yield call(getAllUsersAsync)
    yield put(getAllUserSuccess(response.data))
  } catch (error) {
    console.log(error)
  }
}

function* deleteUser({payload: {userId}}) {
  try {
    const response = yield call(deleteUserAsync, userId)
    if(response.data.id === userId) {
      yield put(deleteUserSuccess(userId))
    }
  } catch (error) {
    console.log(error)
  }
}

function* creatUser({payload: userData}) {
  try {
    const response = yield call(creatUserAsync, userData)
    yield put(createUserSuccess(response?.data?.user))
  } catch (error) {
    console.log(error)
  }
}

function* editUser({payload: {id, userData}}) {
  try {
    const response = yield call(eidtUserAsync, id, userData)
    notification.open({
      message: 'Information',
      description: response.message,
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
    yield put(editUserSuccess(response?.data))
  } catch (error) {
    console.log(error)
  }
}




function* userSaga() {
  yield takeEvery(GET_ALL_USERS, getAllUsers)
  yield takeEvery(DELETE_USER_BYID, deleteUser)
  yield takeEvery(CREATE_USER, creatUser)
  yield takeEvery(EDIT_USER, editUser)
}
export default userSaga
