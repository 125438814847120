export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'

export const DELETE_USER_BYID = 'DELETE_USER_BYID'
export const DELETE_USER_BYID_SUCCESS = 'DELETE_USER_BYID_SUCCESS'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'

export const SET_USER = 'SET_USER'

export const EDIT_USER = 'EDIT_USER'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
