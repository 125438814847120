import React from "react";
import { Route, Redirect } from "react-router-dom";
import { axiosApi } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const Adminmiddleware = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      axiosApi.get(url.ADMIN_VEIRFY).then((res) => {
        const admin = res.data.data;
        if (res.status === 200 && res.statusText === "OK") {
          localStorage.setItem("auth_admin", JSON.stringify(admin));
          if (!admin.role === "super_admin" || !admin.role === "admin") {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }
        }
      });
      return <Component {...props} />;
    }}
  />
);
export default Adminmiddleware;
