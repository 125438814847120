import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

export const adminLogin = data => post(url.ADMIN_LOGIN, data)
export const getAllUsersAsync = () => get(url.GET_ALL_USERS)

export const deleteUserAsync = (userId) => del(`${url.DELETE_USER_BYID}/${userId}`)
export const creatUserAsync = (userData) => post(`${url.CREATE_USER}`, userData)
export const eidtUserAsync = (userId, userData) => put(`${url.EDIT_USER}/${userId}`, userData)
export const loginUserAsyc = data => post(url.USER_LOGIN, data)












