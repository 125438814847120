import React, { useState } from "react";
import { Row, Card, Label, FormGroup, Button, Col } from "reactstrap";
import { NavLink, useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { get, post, axiosApi } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { setUserData } from "../../store/actions";
import { useDispatch } from "react-redux";
import { Spin } from "antd";

const validatePassword = (value) => {
  let error;
  if (!value) {
    error = "Please enter your password";
  } else if (value.length < 4) {
    error = "Value must be longer than 3 characters";
  }
  return error;
};

const validateEmail = (value) => {
  let error;
  if (!value) {
    error = "Please enter your email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email] = useState("");
  const [password] = useState("");
  const [loading, setLoading] = useState(false)

  const onUserLogin = async (values) => {
    if (values.email !== "" && values.password !== "") {
      const userInfo = {
        email: values.email,
        password: values.password,
      };
      setLoading(true)
      const loginRes = await post(url.USER_LOGIN, userInfo);
      if (loginRes.data.success) {
        setLoading(false)
        axiosApi.defaults.headers.common["Authorization"] =
          "Bearer " + loginRes.data.token;
        localStorage.setItem(
          "access_token",
          JSON.stringify(loginRes.data.token)
        );
        const userData = await get(url.USER_VEIRFY);
        localStorage.setItem("auth_user", JSON.stringify(userData.data));
        dispatch(setUserData(userData.data));
        history.push("/home");
      } else {
        setLoading(false)
      }
    }
  };

  const initialValues = { email, password };

  return (
    <Row className="">
      <Col className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side ">
            <p className=" h2">Manager Login</p>
          </div>
          {loading && <Spin />}
          <div className="form-side">
            <NavLink to="/app" className="white">
              <span className="logo-single" />
            </NavLink>
            <Formik initialValues={initialValues} onSubmit={onUserLogin}>
              {({ errors, touched }) => (
                <Form className="av-tooltip tooltip-label-bottom">
                  <FormGroup className="form-group has-float-label">
                    <Label>Email</Label>
                    <Field
                      className="form-control"
                      name="email"
                      validate={validateEmail}
                    />
                    {errors.email && touched.email && (
                      <div className="invalid-feedback d-block">
                        {errors.email}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label>Password</Label>
                    <Field
                      className="form-control"
                      type="password"
                      name="password"
                      validate={validatePassword}
                    />
                    {errors.password && touched.password && (
                      <div className="invalid-feedback d-block">
                        {errors.password}
                      </div>
                    )}
                  </FormGroup>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      color="primary"
                      className="btn-shadow btn-multiple-state"
                      size="lg"
                      type="submit"
                    >
                      <span className="spinner d-inline-block">
                        <span className="bounce1" />
                        <span className="bounce2" />
                        <span className="bounce3" />
                      </span>
                      <span className="label">Login</span>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
