import {
  LOGIN_USER,
  LOGIN_ADMIN,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_ADMIN,
  API_ERROR,
  LOGIN_ADMIN_SUCCESS,
  CHANGE_USER_INFO
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginAdminUser = (adminInfo, history) => {
  return {
    type: LOGIN_ADMIN,
    payload: { adminInfo, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutAdmin = (history) => {
  return {
    type: LOGOUT_ADMIN,
    payload: {history}
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const changeUserInfo = data => {
  return {
    type: CHANGE_USER_INFO,
    payload: data,
  }
}

export const loginAdminSuccess = admin => {
  return {
    type: LOGIN_ADMIN_SUCCESS,
    payload: admin,
  }
}

