import { PoweroffOutlined } from "@ant-design/icons";
import { Layout, Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { logoutAdmin  } from "../../store/actions";
import UserManager from './UserManager'

const { Header, Content } = Layout;

const AdminUsers = (props) => {
  const dispatch = useDispatch();
  
  const logout = () => {
    dispatch(logoutAdmin(props.history));
  };

  return (
    <Layout>
      <Header className="header">
        <div className="d-flex justify-content-between">
          <div className="logo" />
          <div className="logout">
            <Button type="primary" icon={<PoweroffOutlined />} onClick={logout}>
              LogOut
            </Button>
          </div>
        </div>
      </Header>
      <Layout>
        <div className="layout-conainer d-flex">
          <Layout
            style={{
              padding: "24 24px 24px",
            }}
          >
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: "white",
              }}
            >
              <UserManager />
            </Content>
          </Layout>
        </div>
      </Layout>
    </Layout>
  );
};
export default AdminUsers;
