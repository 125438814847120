import axios from 'axios';
import { browserName, mobileVendor, deviceType } from 'react-device-detect';
import {post } from "../helpers/api_helper"
import * as url from "../helpers/url_helper"

export const GetIpAddress = async () => {
  return await axios.get(`https://api.db-ip.com/v2/free/self`)
}

export const getDeviceDetail = async () => {
  let device_info;
  switch (deviceType) {
    case 'browser':
      device_info = {
        "browserName": browserName,
        "deviceType": 'Desktop'
      }
      break;
    case 'mobile':
      device_info = {
        "browserName": browserName,
        "deviceType": mobileVendor
      }
      break;
    case 'tablet':
      device_info = {
        "browserName": browserName,
        "deviceType": deviceType
      }
      break;
    default:
      device_info = {
        "browserName": browserName,
        "deviceType": 'Desktop'
      }
  }
  return device_info;
}

export const sendEmail = async (props) => {

  const emailUrl = process.env.REACT_APP_SERVER_URL + '/api/email.php'

  let status = props.status
  let restaurant_name = props.restaurant_name
  let date = props.date
  let device_info = props.device_info
  let ipAddress = props.ipAddress
  
  let body = `Restaurant ${restaurant_name} has just requested for their website to be ${status} by using the online management application. This has been executed at ${date} using device ${device_info} registered on ${ipAddress}.`

  let emailData = {
    subject: props.subject,
    body: body,
  }

  await axios.post(emailUrl, emailData,  {headers: {'Content-type': 'multipart/form-data'}})

  // const res = await post(url.SEND_EMAIL, emailData)
  // console.log(res)

}

export const sendEmailForOpeningHour = async (props) => {

  const emailUrl = process.env.REACT_APP_SERVER_URL + '/api/email.php'


  let restaurant_name = props.restaurant_name
  let date = props.date
  let device_info = props.device_info
  let ipAddress = props.ipAddress
  
  let hours_for_collection_string = props.hours_for_collection_string
  let hours_for_delivery_string = props.hours_for_delivery_string
  
  let body = `Restaurant ${restaurant_name} has just requested for their website opening hours to be ${hours_for_collection_string} for collection and ${hours_for_delivery_string} for delivery for days {Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday} using the online management application. This has been executed at ${date} using device ${device_info} registered on ${ipAddress}.`

  let emailData = {
    subject: props.subject,
    body: body,
  }

  // const res = await post(url.SEND_EMAIL, emailData)
  // console.log(res)

 await axios.post(emailUrl, emailData,  {headers: {'Content-type': 'multipart/form-data'}})
}

