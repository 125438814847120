import React, { useEffect, useState } from "react";
import { wcGet, wcPut } from "../services/WCService";
import {
  Collapse,
  Spin,
  Divider,
  Button,
  Popconfirm,
  Modal,
  Input,
} from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

const { Panel } = Collapse;

const ManageProduct = () => {
  let history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiUrl, SetApiUrl] = useState("");
  const [categories, SetCategories] = useState([]);
  const [authUser, setAuthUser] = useState(null);
  const [products, SetProducts] = useState([]);
  const [VariableProducts, SetVariableProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [updatePrice, setUpdatePrice] = useState(null);
  const [updateVariablePrice, setUpdateVariablePrice] = useState(null);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentVariableProduct, setCurrentVariableProduct] = useState(null);
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    if (authUser) {
      SetApiUrl(authUser.website_url);
      getAllProductCategories(authUser.website_url);
      setAuthUser(authUser);
    }
  }, []);

  const getAllProductCategories = (apiUrl) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true);
    wcGet(
      `${apiUrl}/wp-json/wc/v3/products/categories?per_page=100&consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`
    ).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        SetCategories(res.data);
      } else {
        alert("there is no category data");
      }
    });
  };

  const goHome = () => {
    history.push("/home");
  };

  const getProductsByCategory = (key) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true);
    SetProducts([]);
    wcGet(
      `${apiUrl}/wp-json/wc/v3/products?category=${key}&per_page=100&consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`
    ).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        SetProducts(res.data);
      } else {
        alert("there is no category data");
      }
    });
  };

  const getProductsByProduct = (productId, variableIDs) => (key) => {
    setActiveKey(activeKey === key ? null : key);
    SetVariableProducts([]);
    setLoading(true);
    Promise.all(variableIDs.map((id) => getVariableProduct(productId, id)))
      .then((results) => {
        SetVariableProducts(results);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getVariableProduct = async (productId, variationId) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    const response = await wcGet(
      `${apiUrl}/wp-json/wc/v3/products/${productId}/variations/${variationId}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`
    );
    return response.data;
  };

  const updateProduct = async (product) => {
    let stock_val = "";
    if (product.stock_status === "instock") {
      stock_val = "outofstock";
    } else {
      stock_val = "instock";
    }
    const data = {
      stock_status: stock_val,
    };
    setLoading(true);
    const authUser = JSON.parse(localStorage.getItem("auth_user"));

    if (product.type === "simple") {
      wcPut(
        `${apiUrl}/wp-json/wc/v3/products/${product.id}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`,
        data
      ).then((res) => {
        setLoading(false);
        let temp_products = [...products];
        let objIndex = temp_products.findIndex((obj) => obj.id === product.id);
        temp_products[objIndex] = res.data;
        SetProducts(temp_products);
      });
    }

    //manage variable type product
    if (product.type === "variable") {
      const variationIds = product.variations;
      const productId = product.id;

      await axios.put(
        `${apiUrl}/wp-json/wc/v3/products/${product.id}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`,
        { manage_stock: false }
      );

      Promise.all(
        variationIds.map((id) =>
          updateVariationStockStatus(productId, id, data)
        )
      )
        .then((results) => {
          let temp_products = [...products];
          let objIndex = temp_products.findIndex(
            (obj) => obj.id === results.productId
          );
          product.stock_status = data.stock_status;
          temp_products[objIndex] = product;
          SetProducts(temp_products);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const updateVariationStockStatus = async (productId, variationId, data) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    const response = await wcPut(
      `${apiUrl}/wp-json/wc/v3/products/${productId}/variations/${variationId}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`,
      data
    );
    return { productId: productId, data: response.data };
  };

  const editPrice = (product) => {
    setCurrentProduct(product);
    setUpdatePrice(product.price);
    setIsModalOpen(true);
  };

  const updateVariableProduct = async (product) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    let stock_val = "";
    if (product.stock_status === "instock") {
      stock_val = "outofstock";
    } else {
      stock_val = "instock";
    }
    const data = {
      stock_status: stock_val,
    };

    setLoading(true);
    const variationIds = product.variations;
    const productId = product.id;

    await axios.put(
      `${apiUrl}/wp-json/wc/v3/products/${productId}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`,
      { manage_stock: false }
    );

    Promise.all(
      variationIds.map((id) =>
        updateVariationStockStatus(productId, id, data)
      )
    )
      .then((results) => {
        let temp_products = [...products];
        let objIndex = temp_products.findIndex(
          (obj) => obj.id === results.productId
        );
        product.stock_status = data.stock_status;
        temp_products[objIndex] = product;
        SetProducts(temp_products);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const editVariablePrice = (productId, item) => {
    setUpdateVariablePrice(item.price);
    setCurrentProductId(productId);
    setCurrentVariableProduct(item);
    setIsVariableModalOpen(true);
  };

  const deleteVaribaleProduct = (productId, item) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true);
    axios
      .delete(
        `${apiUrl}/wp-json/wc/v3/products/${productId}/variations/${item.id}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`,
        { force: true }
      )
      .then((response) => {
        if (response.status === 200) {
          const filteredProducts = VariableProducts.filter(
            (variableItem) => variableItem.id !== item.id
          );
          setLoading(false);
          SetVariableProducts(filteredProducts);
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const deleteProduct = (product) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true);
    axios
      .delete(
        `${apiUrl}/wp-json/wc/v3/products/${product.id}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`,
        { force: true }
      )
      .then((response) => {
        if (response.status === 200) {
          const filteredProducts = products.filter(
            (item) => item.id !== product.id
          );
          setLoading(false);
          SetProducts(filteredProducts);
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const handleUpdatePrice = () => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true);
    const data = { regular_price: updatePrice };
    axios
      .put(
        `${apiUrl}/wp-json/wc/v3/products/${currentProduct.id}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`,
        data
      )
      .then((response) => {
        setLoading(false);
        let temp_products = [...products];
        let objIndex = temp_products.findIndex(
          (obj) => obj.id === currentProduct.id
        );
        temp_products[objIndex] = response.data;
        SetProducts(temp_products);
      })
      .catch((error) => error.response.data);
    setIsModalOpen(false);
  };

  const handleUpdateVariablePrice = () => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true);
    const data = { regular_price: updateVariablePrice };
    axios
      .put(
        `${apiUrl}/wp-json/wc/v3/products/${currentProductId}/variations/${currentVariableProduct.id}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`,
        data
      )
      .then((response) => {
        setLoading(false);
        let temp_products = [...VariableProducts];
        let objIndex = temp_products.findIndex(
          (obj) => obj.id === currentVariableProduct.id
        );
        temp_products[objIndex] = response.data;
        SetVariableProducts(temp_products);
      })
      .catch((error) => error.response.data);
    setIsVariableModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsVariableModalOpen(false);
  };

  const onChangePrice = (e) => {
    setUpdatePrice(e.target.value);
  };

  const onChangeVariablePrice = (e) => {
    setUpdateVariablePrice(e.target.value);
  };

  return (
    <>
      <div className="container">
        <div className="product-header mt-4 ml-2">
          <LeftCircleOutlined style={{ fontSize: "150%" }} onClick={goHome} />{" "}
          Back
        </div>
        <div className="product-panel mt-4">
          <Collapse
            accordion
            onChange={getProductsByCategory}
            expandIconPosition="star"
          >
            {categories.map((item, index) => (
              <Panel header={item.name.replace("&amp;", "&")} key={item.id}>
                {loading && <Spin />}
                {products.map((product, i) => (
                  <>
                    {product.type === "simple" ? (
                      <div
                        key={i}
                        className="row d-flex justify-content-between"
                      >
                        <p className="mt-2 ml-2">
                          {product.name.replace("&amp;", "&")}
                        </p>
                        <div className="item-right d-flex justify-content-center align-items-center">
                          <span>£ {product.price}</span>

                          <Button
                            type="primary"
                            className="ml-2 mr-2"
                            onClick={() => updateProduct(product)}
                            style={{
                              background:
                                product.stock_status === "instock"
                                  ? "green"
                                  : "blue",
                            }}
                          >
                            {product.stock_status === "instock"
                              ? "Instock"
                              : "OutOfStock"}
                          </Button>

                          <Button
                            onClick={() => editPrice(product)}
                            icon={<EditOutlined />}
                            style={{ background: "lightBlue" }}
                          >
                            Edit Price
                          </Button>
                          <Popconfirm
                            title="Delete the Product"
                            description="Are you sure to delete this Product?"
                            onConfirm={() => deleteProduct(product)}
                            onCancel={() => console.log("cancel")}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              style={{ background: "lightBlue" }}
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>

                        <Divider style={{ margin: 0 }} />
                      </div>
                    ) : (
                      <div key={index}>
                        <Collapse
                          accordion
                          activeKey={activeKey}
                          onClick={() => setActiveKey(null)}
                          onChange={getProductsByProduct(
                            product.id,
                            product.variations
                          )}
                          expandIconPosition="start"
                        >
                          <Panel
                            header={product.name.replace("&amp;", "&")}
                            key={i}
                          >
                            <div className="d-flex justify-content-end">
                              <Button
                                type="primary"
                                className="ml-2 mr-2"
                                onClick={() =>
                                  updateVariableProduct(product)
                                }
                                style={{
                                  background:
                                    product.stock_status === "instock"
                                      ? "green"
                                      : "blue",
                                }}
                              >
                                {product.stock_status === "instock"
                                  ? "Instock"
                                  : "OutOfStock"}
                              </Button>
                            </div>
                            {VariableProducts.map((item, j) => (
                              <div
                                key={j}
                                className="row d-flex justify-content-between"
                              >
                                <p className="mt-2 ml-2">
                                  {item.attributes[0].option}
                                </p>
                                <div className="item-right d-flex justify-content-center align-items-center">
                                  <span>£ {item.price}</span>

                                  <Button
                                    onClick={() =>
                                      editVariablePrice(product.id, item)
                                    }
                                    icon={<EditOutlined />}
                                    style={{ background: "lightBlue" }}
                                  >
                                    Edit Price
                                  </Button>
                                  <Popconfirm
                                    title="Delete the Product"
                                    description="Are you sure to delete this Product?"
                                    onConfirm={() =>
                                      deleteVaribaleProduct(product.id, item)
                                    }
                                    onCancel={() => console.log("cancel")}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      icon={<DeleteOutlined />}
                                      style={{ background: "lightBlue" }}
                                    >
                                      Delete
                                    </Button>
                                  </Popconfirm>
                                </div>

                                <Divider style={{ margin: 0 }} />
                              </div>
                            ))}
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                  </>
                ))}
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
      <Modal
        title="Update Price"
        open={isModalOpen}
        onOk={handleUpdatePrice}
        onCancel={handleCancel}
      >
        {loading && <Spin />}
        <Input
          placeholder="Price.."
          value={updatePrice || ""}
          onChange={onChangePrice}
          type="number"
        />
      </Modal>
      <Modal
        title="Update Price"
        open={isVariableModalOpen}
        onOk={handleUpdateVariablePrice}
        onCancel={handleCancel}
      >
        {loading && <Spin />}
        <Input
          placeholder="Price.."
          value={updateVariablePrice || ""}
          onChange={onChangeVariablePrice}
          type="number"
        />
      </Modal>
    </>
  );
};

export default ManageProduct;
