import AdminLogin from "../screens/Authentication/AdminLogin";
import Login from '../screens/Authentication/Login'
import AdminUsers from '../screens/admin/Users'
import Home from '../screens/Home'
import ManageProduct from "../screens/ManageProduct";
import ManageCoupon from "../screens/ManageCoupon";
import ManageOrders from "../screens/ManageOrders";
import { Redirect } from "react-router-dom"

const authRoutes = [
  { path: "/admin/login", component: AdminLogin },
  { path: "/login", component: Login },
  {
    path: "/admin",
    exact: true,
    component: () => <Redirect to="/admin/login" />,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
];

const adminRoutes = [
  { path: "/admin/users", component: AdminUsers },
]

const userRoutes = [
  { path: "/home", component: Home },
  { path: "/manage-product", component: ManageProduct },
  { path: "/coupons", component: ManageCoupon },
  { path: "/orders", component: ManageOrders },

]

export { authRoutes, adminRoutes, userRoutes };
