import { Form, Button, Space, Modal, Input, TimePicker } from "antd";
import dayjs from "dayjs";
import { Spinner } from "react-bootstrap";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { sendEmailForOpeningHour } from "../services/Utils";
import axios from "axios";
import { wcGet } from "../services/WCService";
import moment from "moment";

const UpdateScreen = (props) => {
  const [loading, SetLoading] = useState(false);
  const [deliveryTimes, setDeliveryTimes] = useState([]);
  const [pickupTimes, setPickupTimes] = useState([]);

  useEffect(() => {
    setDeliveryTimes(props.deliveryTimes);
    setPickupTimes(props.pickupTimes);
  }, [props]);


  const handleOk = async () => {
    
    let submitData = {
      week_number: null,
      opening_hours: {
        delivery: deliveryTimes,
        pickup: pickupTimes
      },
    };

    switch (props.title) {
      case "Monday":
        submitData.week_number = 0;
        break;
      case "Tuesday":
        submitData.week_number = 1;
        break;
      case "Wednesday":
        submitData.week_number = 2;
        break;
      case "Thursday":
        submitData.week_number = 3;
        break;
      case "Friday":
        submitData.week_number = 4;
        break;
      case "Saturday":
        submitData.week_number = 5;
        break;
      case "Sunday":
        submitData.week_number = 6;
        break;
    }

    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    console.log('submitData =>', submitData)
    SetLoading(true);
    const updateRes = await axios.post(
      `${props.apiUrl}/wp-json/xc/v1/restaurant-manage-opening-hours?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`,
      submitData
    );
    if (updateRes.data.success) {
      SetLoading(false);
      props.modalHandle(false);
      props.reloadTimesData();

      const restaurantData = await wcGet(
        `${props.apiUrl}/wp-json/xc/v1/restaurant-status`
      );
      const emailDataOpenHour = props.emailDataOpenHour;
      emailDataOpenHour.hours_for_collection_string= JSON.stringify(pickupTimes);
      emailDataOpenHour.hours_for_delivery_string = JSON.stringify(deliveryTimes);

      sendEmailForOpeningHour(emailDataOpenHour);
    }
  };
  
  const handleCancel = () => {
    props.modalHandle(false);
  };

  const handleStartTimeSelectPickup = index => (time, timeString) => {
    setPickupTimes(prevState => {
      const updatedItems = prevState.map((item, key_index) => {
        if (key_index=== index) {
          return { ...item, start_time: moment(time).format('HH:mm') };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const handleEndTimeSelectPickup = index => (time, timeString) => {
    setPickupTimes(prevState => {
      const updatedItems = prevState.map((item, key_index) => {
        if (key_index=== index) {
          return { ...item, end_time: moment(time).format('HH:mm') };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const handleStartTimeSelectDelivery = index => (time, timeString) => {
    setDeliveryTimes(prevState => {
      const updatedItems = prevState.map((item, key_index) => {
        if (key_index=== index) {
          return { ...item, start_time: moment(time).format('HH:mm') };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const handleEndTimeSelectDelivery = index => (time, timeString) => {
    setDeliveryTimes(prevState => {
      const updatedItems = prevState.map((item, key_index) => {
        if (key_index=== index) {
          return { ...item, end_time: moment(time).format('HH:mm') };
        }
        return item;
      });
      return updatedItems;
    });
  };


  const addPickupTime = () => {
    const addData = {
      start_time: '00:00',
      end_time: '00:00'
    }
    setPickupTimes([...pickupTimes, addData])
  }

  const removePickupTime = (index) => {
    setPickupTimes(pickupTimes.filter((item, i) => i !== index));
  }

  const addDeliveryTime = () => {
    const addData = {
      start_time: '00:00',
      end_time: '00:00'
    }
    setDeliveryTimes([...deliveryTimes, addData])
  }

  const removeDeliveryTime = (index) => {
    setDeliveryTimes(deliveryTimes.filter((item, i) => i !== index));
  }


  return (
    <Modal
      title="Update Time"
      open={props.open}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
    >
      <div
        className="update-opening-hours p-4 mb-4"
        style={{ background: "gainsboro" }}
      >
        <div className="row">
          <div className="col-md-6">
            <h6>Opening Times for Collection</h6>
            {pickupTimes &&
              pickupTimes.map((item, index) => {
                return (
                  <div className="d-flex" key={index} style={{marginTop: 10}}>
                     <TimePicker 
                        onSelect={handleStartTimeSelectPickup(index)} 
                        defaultValue={moment(item.start_time, 'HH:mm')}
                        format={'HH:mm'}
                      />
                       <TimePicker 
                        onSelect={handleEndTimeSelectPickup(index)} 
                        defaultValue={moment(item.end_time, 'HH:mm')}
                        format={'HH:mm'}
                      />
                      <Button type="primary" danger style={{marginLeft: 5}} onClick={() => removePickupTime(index)}>remove</Button>
                  </div>

                );
              })}
              <div className="add-btn" style={{marginTop: 20}}>
                <Button type="primary" onClick={addPickupTime}>Add</Button>
              </div>
          </div>

          <div className="col-md-6">
            <h6>Opening Times for Delivery</h6>
            {deliveryTimes &&
              deliveryTimes.map((item, index) => {
                return (
                  <div className="d-flex" key={index} style={{marginTop: 10}}>
                     <TimePicker 
                        onSelect={handleStartTimeSelectDelivery(index)} 
                        defaultValue={moment(item.start_time, 'HH:mm')}
                        format={'HH:mm'}
                      />
                       <TimePicker 
                        onSelect={handleEndTimeSelectDelivery(index)} 
                        defaultValue={moment(item.end_time, 'HH:mm')}
                        format={'HH:mm'}
                      />
                      <Button type="primary" danger style={{marginLeft: 5}} onClick={() => removeDeliveryTime(index)}>remove</Button>
                  </div>

                );
              })}
              <div className="add-btn" style={{marginTop: 20}}>
                <Button type="primary" onClick={addDeliveryTime}>Add</Button>
              </div>
          </div>
        </div>
        {loading ? <Spinner animation="border" variant="success" /> : ""}
      </div>
    </Modal>
  );
};

export default UpdateScreen;
