import { Input } from "antd";
import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import UpdateScreen from "./UpdateScreen";

const Schedule = (props) => {
  const [showUpdateScreen, SetShowUpdateScreen] = useState(false);
  const [emailDataOpenHour, setSmailDataOpenHour] = useState(null)

  const deliveryTimes = [];
  const pickupTimes = [];

  if (props.pickupTimes) {
    Object.keys(props.pickupTimes).forEach((key) => {
      if (key !== "999") {
        pickupTimes.push(props.pickupTimes?.[key]);
      }
    });
  }

  if (props.deliveryTimes)
    Object.keys(props.deliveryTimes).forEach((key) => {
      if (key !== "999") {
        deliveryTimes.push(props.deliveryTimes?.[key]);
      }
    });

  const showEditModal = () => {
    SetShowUpdateScreen(true);
    const resData = props.getEmailDataOpenHour()  
    setSmailDataOpenHour(resData)
  };

  const handleModal = (val) => {
    SetShowUpdateScreen(val);
  };

  const reloadTimesData = () => {
    props.getTimes();
  }

  return (
    <div className="block-day-hour">
      <div className="d-flex">
        <p className="m-0" style={{ color: "blue" }}>
          {props.title} Schedule
        </p>
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          size={"middle"}
          style={{ marginLeft: 10, marginBottom: 8 }}
          onClick={showEditModal}
        />
      </div>
      <hr className="mt-0" />
      <div className="time-container">
        <div className="row">
          <div className="col-md-6">
            <p>Opening Times for Collection</p>
          </div>
          <div className="col-md-6">
            {pickupTimes.length > 0 &&
              pickupTimes.map((item, index) => {
                return (
                  <div className="d-flex mt-2" key={index}>
                    <Input
                      className="mr-4"
                      placeholder="Opening Time"
                      value={item.start_time}
                    />
                    <Input placeholder="Closing Time" value={item.end_time} />
                  </div>
                );
              })}
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <p>Opening Times for Delivery</p>
          </div>
          <div className="col-md-6">
            {deliveryTimes.length > 0 &&
              deliveryTimes.map((item, index) => {
                return (
                  <div className="d-flex mt-2" key={index}>
                    <Input
                      className="mr-4"
                      placeholder="Opening Time"
                      value={item.start_time}
                    />
                    <Input placeholder="Closing Time" value={item.end_time} />
                  </div>
                );
              })}
            <hr />
          </div>
        </div>
      </div>
      {showUpdateScreen && (
        <UpdateScreen
          open={showUpdateScreen}
          modalHandle={handleModal}
          deliveryTimes={deliveryTimes}
          pickupTimes={pickupTimes}
          weekPickup={props.weekPickup}
          weekDelivery={props.weekDelivery}
          title={props.title}
          apiUrl={props.apiUrl}
          reloadTimesData = {reloadTimesData}
          emailDataOpenHour = {emailDataOpenHour}
        />
      )}
    </div>
  );
};

export default Schedule;
