import React, { useEffect, useState, useRef } from "react";
import { wcGet, wcPut } from "../services/WCService";
import { LeftCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { Select, Button, Drawer, Checkbox, Form, Input, DatePicker, Spin, InputNumber, Table, Alert } from 'antd';
import moment from "moment";

const { Option } = Select;

const weekdays = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
]

const orderTypes = [{ value: 'delivery', label: 'Delivery' }, { value: 'collection', label: 'Collection' }]

const ManageCoupon = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [coupons, setCouponse] = useState([]);
  const [initialCoupons, setInitialCoupons] = useState([]);
  const [open, setOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState([]);

  const [restrictWeekdays, setCouponRestrictWeekdays] = useState([]);
  const [ordertypes, setOrderTypes] = useState([]);

  const [categories, setCategories] = useState([])
  const [date_expires, setDateExpires] = useState(null)
  const [individual_use, setIndivisualUse] = useState(false)

  //meta
  const [xc_custom_coupon_start_schedule_date, setStartscheduledate] = useState(null)
  const [_wjecf_is_auto_coupon, setIsAutoCoupon] = useState(null)

  //edit
  const [currentCoupon, setCurrentCoupon] = useState(null)
  const [openEdit, setOpenEdit] = useState(false)
  const [auto_coupon, setAutoCoupon] = useState(false)

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMessage] = useState('')

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    if (authUser) {
      getAllCoupons(authUser.website_url);
      getAllProductCategories(authUser.website_url)
    }
  }, []);

  const getAllCoupons = (apiUrl) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true);
    wcGet(
      `${apiUrl}/wp-json/wc/v3/coupons?per_page=${100}&page=${1}&&consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`, { per_page: 20 }
    ).then((res) => {
      setLoading(false);
      setCouponse(res.data)
      setInitialCoupons(res.data)
    });
  };

  const getAllProductCategories = (apiUrl) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    wcGet(
      `${apiUrl}/wp-json/wc/v3/products/categories?per_page=100&consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`
    ).then((res) => {
      if (res.status === 200) {
        setCategories(res.data);
      } else {
        alert("there is no category data");
      }
    });
  };

  const goHome = () => {
    history.push("/home");
  };

  const handleChange = (value) => {
    if (value === 'all') {
      setCouponse(initialCoupons)
    }
    if (value === 'active') {
      const todayDate = Date.now();
      let temp_coupons = []
      initialCoupons.map((item, index) => {
        if (item.date_expires === null) {
          temp_coupons.push(item)
        } else {
          const expireData = new Date(item.date_expires)
          const miliseconds = expireData.getTime();
          if (todayDate < miliseconds) {
            temp_coupons.push(item)
          }
        }
      })
      setCouponse(temp_coupons)
    }
    if (value === 'expired') {
      const todayDate = Date.now();
      let temp_coupons = []
      initialCoupons.map((item) => {
        if (item.date_expires !== null) {
          const expireData = new Date(item.date_expires)
          const miliseconds = expireData.getTime();
          if (todayDate > miliseconds) {
            temp_coupons.push(item)
          }
        }
      })
      setCouponse(temp_coupons)
    }
  };

  const openModal = () => {
    setOpenEdit(false)
    setIndivisualUse(false)
    setAutoCoupon(false)
    form.setFieldsValue({
      code: '',
      amount: 0,
      discount_type: '',
      minimum_amount: 0,
      date_expires: null,
      xc_custom_coupon_start_schedule_date: null,
      excluded_product_categories: [],
      xc_custom_coupon_restrict_weekdays: [],
      _wjecf_shipping_restrictions: []
    })
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onFinish = async (values) => {
    let reqestData = {
      code: values.code,
      amount: values.amount.toString(),
      discount_type: values.discount_type,
      description: values.code,
      excluded_product_categories: values.excluded_product_categories,
      individual_use: individual_use,
      minimum_amount: values.minimum_amount.toString(),
      date_expires: date_expires
    }

    let meta_data = []
    meta_data.push({ key: '_wjecf_is_auto_coupon', value: _wjecf_is_auto_coupon })
    meta_data.push({ key: 'xc_custom_coupon_start_schedule_date', value: xc_custom_coupon_start_schedule_date })
    meta_data.push({ key: 'xc_custom_coupon_restrict_weekdays', value: restrictWeekdays })

    if (ordertypes.includes('delivery') && ordertypes.includes('collection')) {
      meta_data.push({
        key: '_wjecf_shipping_restrictions', value: [
          "method:flat_rate",
          "method:free_shipping",
          "method:local_pickup",
          "method:distance_rate"
        ]
      })
    } else {
      if (ordertypes.includes('delivery')) {
        meta_data.push({
          key: '_wjecf_shipping_restrictions', value: [
            "method:flat_rate",
            "method:free_shipping",
            "method:distance_rate"
          ]
        })
      }
      if (ordertypes.includes('collection')) {
        meta_data.push({
          key: '_wjecf_shipping_restrictions', value: ['method:local_pickup']
        })
      }
    }
    reqestData.meta_data = meta_data
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true)

    if (openEdit) {
      let editMeta = []
      let expireDate;
      if(form.getFieldValue('date_expires')) {
        expireDate = moment(form.getFieldValue('date_expires')).format('YYYY-MM-DD').toString()
      } else {
        expireDate = ''
      }
      reqestData.date_expires = expireDate

      const stateDate = moment(form.getFieldValue('xc_custom_coupon_start_schedule_date')).format('YYYY-MM-DD').toString()
      editMeta.push({ key: 'xc_custom_coupon_start_schedule_date', value: stateDate })

      if (auto_coupon) {
        editMeta.push({ key: '_wjecf_is_auto_coupon', value: 'yes' })
      } else {
        editMeta.push({ key: '_wjecf_is_auto_coupon', value: 'no' })
      }
      const restrict_weekdays = form.getFieldValue('xc_custom_coupon_restrict_weekdays')
      editMeta.push({ key: 'xc_custom_coupon_restrict_weekdays', value: restrict_weekdays })

      const orderTypeVal = form.getFieldValue('_wjecf_shipping_restrictions')

      if (orderTypeVal.includes('delivery') && orderTypeVal.includes('collection')) {
        editMeta.push({
          key: '_wjecf_shipping_restrictions', value: [
            "method:flat_rate",
            "method:free_shipping",
            "method:local_pickup",
            "method:distance_rate"
          ]
        })
      } else {
        if (orderTypeVal.includes('delivery')) {
          editMeta.push({
            key: '_wjecf_shipping_restrictions', value: [
              "method:flat_rate",
              "method:free_shipping",
              "method:distance_rate"
            ]
          })
        }
        if (orderTypeVal.includes('collection')) {
          editMeta.push({
            key: '_wjecf_shipping_restrictions', value: ['method:local_pickup']
          })
        }
      }

      if (currentCoupon.meta_data.length > 0) {
        let temp = []
        currentCoupon.meta_data.map((item, index) => {
          editMeta.map((metaItem, j) => {
            if (item.key === metaItem.key) {
              item.value = metaItem.value
            }
          })
        })

        editMeta.map((metaItem, i) => {
          let find = false;
          currentCoupon.meta_data.map((item, j) => {
            if (metaItem.key === item.key) {
              find = true;
            }
          })
          if(!find) temp.push(metaItem);
        })

        currentCoupon.meta_data = [...currentCoupon.meta_data, ...temp];
        reqestData.meta_data.splice(0, reqestData.meta_data.length, ...currentCoupon.meta_data);

      } else {
        reqestData.meta_data.splice(0, reqestData.meta_data.length, ...editMeta);
      }

      const res = await axios.put(`${authUser.website_url}/wp-json/wc/v3/coupons/${currentCoupon.id}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`, reqestData)
      setLoading(false)
      if (res.status === 200) {
        const updatedCoupons = coupons.map(coupon =>
          coupon.id === currentCoupon.id ? res.data : coupon
        );
        setCouponse(updatedCoupons);
        setOpen(false)
      }
    } else {
      axios.post(`${authUser.website_url}/wp-json/wc/v3/coupons?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`, reqestData).then(res => {
        setLoading(false)
        if (res.status === 201) {
          setCouponse([res.data, ...coupons])
          setOpen(false)
          setError(false)
        } 
      })
        .catch((error) => {
          setLoading(false)
          setError(true)
          setErrorMessage(error.response?.data?.message)
        })

    }
  };

  const onChangeExpireDate = (date, dateString) => {
    setDateExpires(dateString)
  };

  const onChangeMedataDate = (date, dateString) => {
    setStartscheduledate(dateString)
  }


  const onChangeIndiviaulUse = (e) => {
    setIndivisualUse(e.target.checked)
  }

  const onChangeIsautocoupon = (e) => {
    setAutoCoupon(e.target.checked)
    e.target.checked ? setIsAutoCoupon('yes') : setIsAutoCoupon('no')

  }

  const deleteCoupon = async (id) => {
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    setLoading(true)
    const res = await axios.delete(
      `${authUser.website_url}/wp-json/wc/v3/coupons/${id}?consumer_key=${authUser.custom_key}&consumer_secret=${authUser.custom_secret}`, { force: true })
    console.log(res)
    setLoading(false)
  }

  const editCoupon = (item) => {
    console.log('edit item =>', item)
    setCurrentCoupon(item)
    setOpenEdit(true)
    setOpen(true)
    let start_data = null;
    let weekdayVal = [];
    let shipping_restrictions = []
    if (item.meta_data?.length > 0) {
      start_data = item.meta_data.find((one) => one.key === "xc_custom_coupon_start_schedule_date")?.value
      weekdayVal = item.meta_data.find((one) => one.key === "xc_custom_coupon_restrict_weekdays")?.value
      let orderTypeData = item.meta_data.find((one) => one.key === "_wjecf_shipping_restrictions")?.value
      
      if (orderTypeData?.length === 4) {
        shipping_restrictions = ['delivery', 'collection']
      }
      if (orderTypeData?.length === 3) {
        shipping_restrictions = ['delivery']
      }
      if (orderTypeData?.length === 1) {
        shipping_restrictions = ['collection']
      }

      const autoCouponData = item.meta_data.find((one) => one.key === "_wjecf_is_auto_coupon")?.value
      if (autoCouponData === 'yes') {
        setAutoCoupon(true)
      } else {
        setAutoCoupon(false)
      }
    }
    setIndivisualUse(item.individual_use)

    form.setFieldsValue({
      code: item.code,
      amount: Number(item.amount),
      discount_type: item.discount_type,
      minimum_amount: Number(item.minimum_amount),
      date_expires: item.date_expires ? moment(item.date_expires, "YYYY-MM-DD") : null,
      xc_custom_coupon_start_schedule_date: start_data ? moment(start_data, "YYYY-MM-DD") : null,
      excluded_product_categories: item.excluded_product_categories,
      xc_custom_coupon_restrict_weekdays: weekdayVal,
      _wjecf_shipping_restrictions: shipping_restrictions
    })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Discount Type',
      dataIndex: 'discount_type',
      key: 'discount_type',
    },
    {
      title: 'Minumum Amount',
      dataIndex: 'minimum_amount',
      key: 'minimum_amount',
    },
    {
      title: 'Date Expire',
      dataIndex: 'date_expires',
      key: 'date_expires',
    },
    {
      title: 'Individual Use',
      dataIndex: 'individual_use',
      key: 'individual_use',
      render: (record) => <a>{record ? 'True' : 'False'}</a>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          style={{ background: "lightBlue" }}
          onClick={() => editCoupon(record)}
        >
          Edit
        </Button>
      ),
    },
  ];

  console.log('couponse =>', coupons)

  return (
    <>
      <div className="container">
        <div className="product-header mt-4 ml-2">
          <LeftCircleOutlined style={{ fontSize: "150%" }} onClick={goHome} />{" "}
          Back
        </div>
        <div className="coupon-title" style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>
          Coupons Management
        </div>
        {loading && <Spin />}
        <div className="coupon-content">
          <div className="coupon-filter d-flex justify-content-between">
            <Select
              defaultValue="All"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: 'all', label: 'All' },
                { value: 'active', label: 'Active' },
                { value: 'expired', label: 'Expired' },
              ]}
            />
            <Button type="primary" onClick={openModal}> Create Coupon </Button>
          </div>
          <div className="coupon-table" style={{ marginTop: 10 }}>
            <Table columns={columns} dataSource={coupons} />
          </div>
        </div>

        <Drawer title={openEdit ? 'Edit Coupon' : 'Create Coupon'} placement="right" onClose={onClose} open={open} width={700}>
          {loading && <Spin />}
          {error && (
            <Alert
              message="Error"
              description={errorMsg}
              type="error"
              showIcon
              style={{marginBottom: 20}}
            />
          )}

          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Please input your code!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input your amount!',
                },
              ]}
            >
              <InputNumber min={0} max={100000} />
            </Form.Item>

            <Form.Item
              name="discount_type"
              label="Discount Type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select a option and change input text above"
                allowClear
              >
                <Option value="percent">Percent</Option>
                <Option value="fixed_cart">Fixed Basket</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Minimum Amount"
              name="minimum_amount"
              rules={[
                {
                  required: true,
                  message: 'Please input your minimum amount!',
                },
              ]}
            >
              <InputNumber min={0} max={100000} />
            </Form.Item>

            <Form.Item
                label="Order Type"
                name="_wjecf_shipping_restrictions"
                rules={[
                  {
                    required: true,
                    message: 'Please select the order type',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="order type"
                  value={ordertypes}
                  onChange={setOrderTypes}
                  style={{
                    width: '100%',
                  }}
                  options={orderTypes.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                />
              </Form.Item>

            <Form.Item
              label="Expire Date"
              name="date_expires"
            >
              <DatePicker onChange={onChangeExpireDate} />
            </Form.Item>

            <Form.Item
              label="Start Date"
              name="xc_custom_coupon_start_schedule_date"
            >
              <DatePicker onChange={onChangeMedataDate} />
            </Form.Item>

            <Form.Item
              name="individual_use"
              style={{ marginLeft: 100 }}
            >
              Individual Use : <Checkbox onChange={onChangeIndiviaulUse} checked={individual_use} />
            </Form.Item>

            <Form.Item
              label="excluded product categories"
              name="excluded_product_categories"
            >
              <Select
                mode="multiple"
                placeholder="categories"
                value={selectedItems}
                onChange={setSelectedItems}
                style={{
                  width: '100%',
                }}
                options={categories.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>

            <div className="meta-content">
              <Form.Item
                label="Apply Automatically"
                name="_wjecf_is_auto_coupon"
              >
                <Checkbox onChange={onChangeIsautocoupon} checked={auto_coupon} />
              </Form.Item>


              <Form.Item
                label="Weekdays"
                name="xc_custom_coupon_restrict_weekdays"
              >
                <Select
                  mode="multiple"
                  placeholder="restrict weekdays"
                  value={restrictWeekdays}
                  onChange={setCouponRestrictWeekdays}
                  style={{
                    width: '100%',
                  }}
                  options={weekdays.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                />
              </Form.Item>

            
            </div>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
              style={{ marginTop: 20 }}
            >
              <Button type="primary" htmlType="submit">
                {openEdit ? 'Update' : 'Create'}
              </Button>
            </Form.Item>
          </Form>
        </Drawer>

      </div>
    </>
  );
};

export default ManageCoupon;
